import {
  ImpersonationContext,
  fsCollectionListener,
  fsUpdateDoc,
} from '@monash/portal-frontend-common';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Data } from '../data-provider/DataProvider';
import { useFeatureFlags } from 'hooks/use-feature-flags';
import { checkShouldModalOpen } from './utils';

export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const { currentUser } = useContext(ImpersonationContext);
  const USER_ROLES = currentUser?.organizationalRelationships;

  const [isModalOpen, setIsModalOpen] = useState(false);

  // get seenList from firebase
  const [showNotificationsList, setShowNotificationsList] = useState({});
  const { portalPreferences, setPortalPreferences } = useContext(Data);
  const [seenList, setSeenList] = useState({});

  // Update
  const updateNotification = (updatedSeenList) => {
    const id = currentUser.uid;
    const path = `users/${id}`;

    fsUpdateDoc(path, {
      'preferences.seenNotifications': updatedSeenList,
    })
      .then(() => handleUpdateSuccess(updatedSeenList))
      .catch(handleUpdateError);
  };

  const handleUpdateSuccess = (updatedSeenList) => {
    setPortalPreferences((f) => {
      return { ...f, seenNotifications: updatedSeenList };
    });
  };

  const handleUpdateError = (error) => {
    console.warn(
      '[updatePortalPreferences]: api call error, failed to update seen notifications',
      error
    );
  };

  // Get active notifications from firebase
  useEffect(() => {
    const getNotificationList = () =>
      fsCollectionListener('activeNotifications', (querySnapshot) => {
        // All active notifications
        const notificationList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // the active notifications the current user should see
        if (portalPreferences) {
          setSeenList(portalPreferences.seenNotifications || {});
          updateShowNotificationsList(
            notificationList,
            portalPreferences.seenNotifications || {}
          );
        }
      });

    getNotificationList();
  }, [portalPreferences]);

  // Map out the notification(s) that should shown base on user group(s)
  const updateShowNotificationsList = (notificationList, seenNotifications) => {
    // Check if user should see this notification base on user's roles
    const list = notificationList.filter((notification) =>
      USER_ROLES?.some((x) => notification?.studentUserGroups.includes(x))
    );
    const notificationsObject = list?.reduce((acc, notification) => {
      acc[notification.id] = notification;
      return acc;
    }, {});

    // Remove outdated notification from seen list and firebase
    for (const [id] of Object.entries(seenNotifications)) {
      if (!notificationsObject[id]) {
        setSeenList((f) => {
          const updated = { ...f };
          delete updated[id];
          updateNotification(updated);
          return updated;
        });
      }
    }

    setIsModalOpen(
      checkShouldModalOpen(notificationsObject, seenNotifications)
    );
    setShowNotificationsList(notificationsObject);
  };
  const featureFlags = useFeatureFlags();

  return (
    <>
      {featureFlags?.CRITICAL_AND_NON_CRITICAL_NOTIFICATIONS ? (
        <NotificationContext.Provider
          value={{
            checkShouldModalOpen: isModalOpen,
            showNotificationsList,
            setShowNotificationsList,
            seenList,
            setSeenList,
            updateNotification,
          }}
        >
          {children}
        </NotificationContext.Provider>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
export default NotificationProvider;
