import classnames from 'classnames';
import { Accordion, VirtuallyHidden } from '@monash/portal-react';
import c from './workshop-accordion.module.scss';
import { getDisplayStatusComponent, getDisplayStatus } from './render-status';
import { getTimeOrDueDate } from './get-time-or-due-date';
import { displayOpenDate } from './display-open-date';
import getWorkshopText from './get-workshop-text';
import { formatTimeDistance } from 'components/providers/data-provider/utils';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { GRADE_SCALE_TYPES_WITHOUT_LIST } from 'constants/grades';
import { GradesList } from './GradesList/GradesList';
import { renderGradesList } from './render-grades-list';
import { renderGrade } from './render-grade';
import fs from 'styles/font-styles.module.scss';

const WorkshopAccordion = ({
  linkedWorkshopEvents,
  currentDate,
  activeIndex,
  stylesheet,
  mode,
  gradeScaleTypes,
  featureFlags,
}) => {
  const cs = stylesheet;
  const items = linkedWorkshopEvents.map((event, index) => {
    const { data } = event;
    const isGraded = Boolean(data?.gradeScaleType);
    const gradesList = gradeScaleTypes?.[data?.gradeScaleType];
    const hasGradesList =
      gradesList &&
      !GRADE_SCALE_TYPES_WITHOUT_LIST.includes(data?.gradeScaleType);
    const status = data?.status;
    const submissionStartDate = event?.data?.submissionStartDate?.time;

    const titleWrapperClasses = classnames(c.titleWrapper, c[mode]);
    const numberClasses = classnames(c.number, c[mode], {
      [c.active]: index === activeIndex,
    });

    const displayStatus = getDisplayStatus({
      status,
      currentDate,
      submissionStartDate,
    });

    const openDate = displayOpenDate({
      eventType: event.eventType,
      currentDate,
      openDate: submissionStartDate,
    });

    const showTimeDistance =
      currentDate < event.start.time && displayStatus !== 'SUBMITTED';

    return {
      title: (
        <div className={titleWrapperClasses}>
          <div className={numberClasses}>{index + 1}</div>{' '}
          {getWorkshopText(event.eventType)}
          {
            // for screen readers to convey that this is the section that has the filled in number background, i.e. to indicate that it contains the info for the event that the user selected
            index === activeIndex && <VirtuallyHidden text="associated" />
          }
        </div>
      ),
      content: (
        <div className={c.contentWrapper} key={event.id}>
          {openDate && (
            <div>
              <div className={cs.label}>Open date</div>
              <div>{openDate}</div>
            </div>
          )}

          <div>
            <div className={cs.label}>Due date</div>
            <div className={cs.largeTime}>{getTimeOrDueDate(event)}</div>
          </div>

          {mode === 'card' &&
            showTimeDistance && ( // TODO: replace all instances of "card"/"canvas" with enum
              <div>
                <div className={cs.label}>Due in</div>
                <div className={cs.largeTime}>
                  {formatTimeDistance(event.start?.time, currentDate)}
                </div>
              </div>
            )}

          <div>
            <div className={cs.label}>Status</div>
            <div className={cs.status}>
              {getDisplayStatusComponent({ status: displayStatus, mode })}
            </div>
          </div>

          {featureFlags?.GRADES && isGraded && (
            <div className={c.detailSection}>
              {hasGradesList ? (
                <GradesList
                  items={[
                    {
                      title: 'Grade',
                      content: renderGradesList(
                        gradesList,
                        data?.gradeFormatted
                      ),
                    },
                  ]}
                  data={event.data}
                />
              ) : (
                <>
                  <div className={cs.label}>Grade</div>
                  <div className={fs.body}>
                    {renderGrade(event.data, false)}
                  </div>
                </>
              )}
            </div>
          )}

          <div>
            <div className={cs.label}>Type</div>
            <div>{capitaliseFirstWord(event.eventType)}</div>
          </div>
        </div>
      ),
    };
  });

  return (
    <>
      <Accordion
        className={classnames(c.customAccordion, c[mode])}
        useCustomStyles
        hasExpandAll={false}
        items={items}
        defaultExpandedIndex={activeIndex}
      />
    </>
  );
};

export default WorkshopAccordion;
