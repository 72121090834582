export const checkShouldModalOpen = (
  notificationsObject,
  seenNotifications
) => {
  // Check if user has seen all notifications which decides if the modal should be open
  for (const [id, item] of Object.entries(notificationsObject)) {
    // Check if a notification has been seen

    const seenItem = seenNotifications && seenNotifications[id];
    if (seenItem) {
      if (item.version === seenItem.version) {
        // Delete non critical dismissed notification from modal and badge
        if (seenItem.dismissed === true) {
          delete notificationsObject[id];
        }
      } else {
        // If the notification has been updated
        return true;
      }
    } else {
      // If the notification is not in the seen notifications list
      return true;
    }
  }
  // If all notifications have been seen
  return false;
};
