import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Badge,
  Icon,
  Modal,
  ModalSection,
  Tooltip,
  VirtuallyHidden,
} from '@monash/portal-react';
import { NotificationContext } from 'components/providers/notification-provider/NotificationProvider';
import Notification from './notification/Notification';
import c from './notification-badge.module.scss';
import isEqual from 'lodash.isequal';
import { notificationType } from '../../../../constants';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { AccessibilityContext } from '@monash/portal-frontend-common';
const NotificationsBadge = () => {
  const {
    shouldModalOpen,
    showNotificationsList,
    setShowNotificationsList,
    seenList,
    setSeenList,
    updateNotification,
  } = useContext(NotificationContext);
  const { CRITICAL, NON_CRITICAL } = notificationType;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { resetAppLiveMsgs, setAppLivePoliteMsg } =
    useContext(AccessibilityContext);
  const [refreshModalFocusablesCount, setRefreshModalFocusablesCount] =
    useState(0);

  const [focusablesList, setFocusablesList] = useState([]);

  const { addSnackbar } = useSnackbar();
  const containerRef = useRef(null);

  const titleRef = useRef();
  useEffect(() => {
    if (shouldModalOpen) {
      setIsModalOpen(true);
      handleFocus();
    }
  }, [shouldModalOpen, titleRef.current]);

  const getFocusablesList = (list) => {
    setFocusablesList(list);
  };

  const handleFocus = () => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    // When user close modal
    const tempSeenList = Object.entries(showNotificationsList)?.reduce(
      (acc, [key, notification]) => {
        acc[key] = {
          id: notification?.id,
          version: notification?.version,
          dismissed: Boolean(notification?.dismissed),
        };
        return acc;
      },
      {}
    );

    // Check if should update seen list
    const hasDifference = !isEqual(seenList, tempSeenList);

    if (hasDifference) {
      setSeenList((f) => {
        const newSeenList = { ...f, ...tempSeenList };
        updateNotification(newSeenList);
        return newSeenList;
      });
    }

    setIsModalOpen(false);
    triggerRef.current.focus();
  };

  const deleteNotification = (idToRemove, idInTheList) => {
    // get the deleted notification object
    const foundNotification = showNotificationsList[idToRemove];

    const newDeleted = {
      id: foundNotification.id,
      version: foundNotification.version,
      dismissed: true,
    };

    // update the notification display without the delete item
    setShowNotificationsList((prevShowNotifications) => {
      const filteredNotifications = { ...prevShowNotifications };
      delete filteredNotifications[idToRemove];
      return filteredNotifications;
    });

    // update the notification in seenList with deleted:true
    setSeenList((f) => {
      const updated = { ...f, [idToRemove]: newDeleted };
      updateNotification(updated);
      return updated;
    });

    // create local polite message
    resetLocalPoliteLiveMsg();
    setTimeout(() => {
      setAppLivePoliteLocalMsg('Notification has been removed');
    }, 1000);

    if (nonCriticalNotificationList.length - 1 > idInTheList) {
      focusablesList[idInTheList + 1]?.focus();
    } else {
      focusablesList[0]?.focus();
    }

    if (
      nonCriticalNotificationList.length === 1 &&
      criticalNotificationList.length === 0
    ) {
      addSnackbarAfterDeletion();
    }
  };

  const addSnackbarAfterDeletion = () => {
    // clear residue live messages
    resetAppLiveMsgs();
    addSnackbar({
      message: 'Notification has been removed',
      type: 'success',
    });
  };

  useEffect(() => {
    setRefreshModalFocusablesCount((prevCount) => prevCount + 1);
  }, [seenList]);

  const triggerRef = useRef();

  // Render the display of the notifications on modal
  const notificationsArray = Object.values(showNotificationsList || {});

  const criticalNotifications = notificationsArray.filter(
    (n) => n.type === CRITICAL
  );
  const nonCriticalNotifications = notificationsArray.filter(
    (n) => n.type === NON_CRITICAL
  );

  const criticalNotificationList = criticalNotifications.map((notification) => (
    <Notification notification={notification} key={notification.title} />
  ));
  const nonCriticalNotificationList = nonCriticalNotifications.map(
    (notification, i) => (
      <Notification
        notification={notification}
        key={notification.title}
        getDeletedNotification={deleteNotification}
        idInTheList={i}
      />
    )
  );
  const MODAL_FOCUSABLES_SELECTOR = 'button[type="button"]';
  const modalTitle = 'Notifications';

  useEffect(() => {
    resetAppLiveMsgs();
    if (Object.keys(showNotificationsList).length !== 0) {
      setAppLivePoliteMsg(
        `There are ${Object.keys(showNotificationsList).length} notifications`
      );
    } else {
      setAppLivePoliteMsg('');
    }
  }, [showNotificationsList]);

  const [appLivePoliteLocalMsg, setAppLivePoliteLocalMsg] = useState('');
  const resetLocalPoliteLiveMsg = () => {
    setAppLivePoliteLocalMsg('\u00A0');
  };
  return (
    <>
      {Object.entries(showNotificationsList).length > 0 ? (
        <div>
          <Tooltip title={modalTitle}>
            <button
              className={c.notificationContainer}
              ref={triggerRef}
              onClick={handleOpen}
              type="button"
              aria-label={`Notification ${
                Object.keys(showNotificationsList).length
              } item${
                Object.keys(showNotificationsList).length > 1 ? 's' : ''
              }`}
              data-tracking-event="topnav-notifications"
              aria-haspopup="dialog"
            >
              <div className={c.notifications}>
                {criticalNotificationList.length > 0 ? (
                  <Icon.Warning color="var(--color-intent-attention)" />
                ) : null}
                {nonCriticalNotificationList.length > 0 ? (
                  <Icon.Alert color="var(--color-intent-information)" />
                ) : null}
              </div>
              <Badge
                count={Object.keys(showNotificationsList).length}
                offset={[-4, 4]}
              />
            </button>
          </Tooltip>
          <Modal
            triggerRef={triggerRef}
            open={isModalOpen}
            onClose={handleClose}
            ariaLabel={modalTitle}
            role="none"
            focusablesSelector={MODAL_FOCUSABLES_SELECTOR}
            refreshFocusables={refreshModalFocusablesCount}
            ref={containerRef}
            getFocusablesList={getFocusablesList}
          >
            <ModalSection
              titleRef={titleRef}
              title={
                'Notification' +
                `${Object.keys(showNotificationsList).length ? 's' : ''}`
              }
              ariaLabelledby=""
              ariaDescribedby=""
            >
              <VirtuallyHidden id="app-live-regions">
                <div
                  id="app-live-region-polite"
                  aria-live="polite"
                  aria-atomic="true"
                  aria-relevant="additions"
                >
                  {appLivePoliteLocalMsg}
                </div>
              </VirtuallyHidden>
              <div className={c.notificationList}>
                {criticalNotificationList}
                {nonCriticalNotificationList}
              </div>
            </ModalSection>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default NotificationsBadge;
