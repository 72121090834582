import React from 'react';
import { Icon, IconButton, InlineExternalLink } from '@monash/portal-react';
import c from './notification.module.scss';
import fs from 'styles/font-styles.module.scss';
import { notificationType } from '../../../../../constants';

const Notification = ({
  notification,
  getDeletedNotification,
  idInTheList,
}) => {
  const { NON_CRITICAL } = notificationType;
  return (
    <>
      <div
        key={notification.id}
        className={c.notificationItem}
        aria-label={
          notification.type === NON_CRITICAL
            ? 'Notification'
            : 'Critical notification'
        }
      >
        <div className={[fs.bold, fs.md, c.titleContainer].join(' ')}>
          <h3 className={c.title}>
            {notification.type === NON_CRITICAL ? (
              <Icon.Alert color="var(--color-intent-information)" />
            ) : (
              <Icon.Warning color="var(--color-intent-attention)" />
            )}
            {notification.title}
          </h3>
          {notification.type === NON_CRITICAL ? (
            <IconButton
              icon={Icon.Trash}
              onClick={() =>
                getDeletedNotification(notification.id, idInTheList)
              }
              aria-label="Delete"
            />
          ) : null}
        </div>
        <div>{notification.description}</div>
        {notification?.links
          ? notification?.links.map((link) => (
              <InlineExternalLink
                key={link.label}
                text={link.label}
                link={link.url}
                mode="card"
              />
            ))
          : null}
      </div>
    </>
  );
};

export default Notification;
