import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  deepClone,
  fsUpdateDoc,
  ImpersonationContext,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { DragOverlay } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { getColumns, getColumnSpan, getRows, RESIZE_DELAY } from './utils';
import { Data } from 'components/providers/data-provider/DataProvider';
import { Card, useMedia } from '@monash/portal-react';
import PageContainer from '../PageContainer';
import WidgetContainer from './widget-container/WidgetContainer';
import SortableGridItem from '../../ui/drag-and-drop/SortableGridItem';
import SortableDragAndDropWrapper, {
  DND_TYPE,
} from '../../ui/drag-and-drop/SortableDragAndDropWrapper';
import Header from './header/Header';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { WidgetContext } from '../../providers/WidgetProvider';
import { getWidgetNameByTypeId } from './widgets/widgetDirectory';
import useKeyNavGroups, { KEY_NAV_MODE } from 'hooks/use-key-nav-groups';
import EmptyPage from './empty-page/EmptyPage';
import { Page } from 'components/providers/page-provider/PageProvider';
import c from './custom.module.scss';
import WidgetError from './widget-container/widget-error/WidgetError';
import WIDGET_ERROR_MESSAGE from './widget-container/widget-error/WidgetErrorMessage';

const Custom = forwardRef(({ i, pageId, selected }, ref) => {
  const size = useMedia();
  const { currentUser } = useContext(ImpersonationContext);
  const { addSnackbar } = useSnackbar();
  const { isGroupKeyNavDisabled } = useContext(WidgetContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);

  // data
  const { setPortalPreferences } = useContext(Data);
  const { pagesData } = useContext(Page);
  const widgets = pagesData?.widgets;
  const pageData = pagesData?.customPages[pageId];
  const [widgetOrder, setWidgetOrder] = useState(pageData?.widgetOrder || []);
  const widgetOrderHasBeenChangedByDragAndDrop =
    JSON.stringify(widgetOrder) !== JSON.stringify(pageData?.widgetOrder);
  const [newWidgetId, setNewWidgetId] = useState(null);
  const isPageEmpty = widgetOrder?.length === 0;

  // edit
  const [inEditMode, setInEditMode] = useState(false);
  const [activeId, setActiveId] = useState(null); // id of widget currently being dragged & dropped
  const [isDragging, setIsDragging] = useState(false);
  const [blueprint, setBlueprint] = useState({ rows: [], columns: [] });
  const [updatingWidgetOrderStatus, setUpdatingWidgetOrderStatus] =
    useState('initial');

  const renderWidget = (widgetId, widget, widgetIndex, isOverlay) => (
    <SentryErrorBoundary
      key={widgetId}
      fallback={
        <Card>
          <WidgetError message={WIDGET_ERROR_MESSAGE.CODE_BROKEN} />
        </Card>
      }
    >
      <WidgetContainer
        size={size}
        key={widgetId}
        pageId={pageId}
        widgetId={widgetId}
        widget={widget}
        widgetIndex={widgetIndex}
        totalWidgetCount={widgetOrder.length}
        isOverlay={isOverlay}
        inEditMode={inEditMode}
        onSelectedPage={selected}
        newWidgetId={newWidgetId}
        setNewWidgetId={setNewWidgetId}
        widgetOrder={widgetOrder}
        setWidgetOrder={setWidgetOrder}
      />
    </SentryErrorBoundary>
  );

  const updateWidgetOrder = () => {
    setUpdatingWidgetOrderStatus('updating');
    const preferencesDocPath = `users/${currentUser.uid}`;

    const newPages = deepClone(pagesData);
    newPages.customPages[pageId].widgetOrder = [...widgetOrder];

    fsUpdateDoc(preferencesDocPath, {
      'preferences.pages': newPages,
    })
      .then(() => handleUpdateSuccess(newPages))
      .catch(handleUpdateError);
  };

  const handleUpdateSuccess = (newPages) => {
    setPortalPreferences((f) => {
      return { ...f, pages: newPages };
    });
    setUpdatingWidgetOrderStatus('completed');
  };

  const handleUpdateError = (error) => {
    setUpdatingWidgetOrderStatus('completed');
    resetAppLiveMsgs();
    addSnackbar({
      message:
        "Oops, you can't re-order widgets right now \u2013 try again later",
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to update widget order.',
      error
    );
  };

  // drag and drop
  const boardRef = useRef();
  const dropAreaRef = useRef();
  const dropAreaPositions = dropAreaRef.current?.getBoundingClientRect();

  // onDragOver
  const swapWidgetOrder = (e) => {
    const { active, over } = e;
    if (over && active.id !== over.id) {
      setWidgetOrder((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // onDragStart
  const activateWidget = (e) => {
    setIsDragging(true);
    setActiveId(e.active.id);
  };

  const deactivateActiveWidget = () => {
    setIsDragging(false);
    setActiveId(null);
  };

  // onDragEnd
  const onDragEnd = () => {
    deactivateActiveWidget();
    if (widgetOrderHasBeenChangedByDragAndDrop) {
      updateWidgetOrder();
    }
  };

  // onDragCancel
  const onDragCancel = () => {
    deactivateActiveWidget();
    // revert widget order
    setWidgetOrder(pageData.widgetOrder);
  };

  // screen reader announcements
  const screenReaderAnnouncements = {
    onDragCancel() {
      return `Sorting cancelled. Widget was dropped and returned to position: ${
        pageData.widgetOrder?.indexOf(activeId) + 1
      } of ${widgetOrder?.length}.`;
    },
  };

  // blueprint
  const renderColumns = () => {
    return (
      <ul
        className={`${c.columns} ${isDragging && c.dragging}`}
        style={{
          height: `${boardRef.current?.getBoundingClientRect().height + 80}px`,
        }}
      >
        {blueprint.columns.map((line, i) => (
          <li className={c.line} key={i} style={{ left: line }} />
        ))}
      </ul>
    );
  };

  const renderRows = () => {
    return (
      <ul className={`${c.rows} ${isDragging && c.dragging}`}>
        {blueprint.rows.map((line, i) => (
          <li
            className={c.line}
            key={i}
            style={{
              top: line,
            }}
          />
        ))}
      </ul>
    );
  };

  // update blueprint refs
  const intervalRef = useRef();
  const intervalCountRef = useRef();
  const dropAreaDebounceRef = useRef();

  // update blueprint fns
  const cleanCurrentInterval = () => clearInterval(intervalRef.current);
  const updateBlueprint = useCallback(
    (size) => {
      setBlueprint({
        rows: getRows(boardRef),
        columns: getColumns(
          boardRef.current?.getBoundingClientRect().width,
          size
        ),
      });
    },
    [size]
  );
  const updateBlueprintPeriodicallyWithTransitionDelay = () => {
    // both widget and page has a size transition delay
    intervalCountRef.current = RESIZE_DELAY / 100;
    cleanCurrentInterval();
    intervalRef.current = setInterval(() => {
      if (
        JSON.stringify(dropAreaPositions) ===
        JSON.stringify(dropAreaDebounceRef.current)
      ) {
        updateBlueprint(size);
      } else {
        dropAreaDebounceRef.current = dropAreaPositions;
      }
      if (intervalCountRef.current > 0) {
        intervalCountRef.current--;
      } else {
        cleanCurrentInterval();
      }
    }, 100);
  };

  // update blue print when enter edit mode
  useEffect(() => {
    if (inEditMode) {
      updateBlueprintPeriodicallyWithTransitionDelay();
    }
    return cleanCurrentInterval;
  }, [inEditMode]);

  // update blue print on drag end or cancel
  useEffect(() => {
    const shouldUpdateBlueprint =
      inEditMode && !activeId && widgetOrderHasBeenChangedByDragAndDrop;
    if (shouldUpdateBlueprint) {
      updateBlueprintPeriodicallyWithTransitionDelay();
      return cleanCurrentInterval;
    }
  }, [activeId]);

  // update blueprint while widgets or widget order has changed due to data updating actions
  useEffect(() => {
    const shouldUpdateBlueprint = inEditMode && !activeId;
    if (shouldUpdateBlueprint) {
      updateBlueprintPeriodicallyWithTransitionDelay();
      return cleanCurrentInterval;
    }
  }, [widgetOrder, widgets]);

  // update blue print on viewport resize
  useLayoutEffect(() => {
    if (inEditMode) {
      window.addEventListener(
        'resize',
        updateBlueprintPeriodicallyWithTransitionDelay
      );
    }
    return () => {
      window.removeEventListener(
        'resize',
        updateBlueprintPeriodicallyWithTransitionDelay
      );
    };
  }, [size, inEditMode]);

  // update blue print while dragging
  useLayoutEffect(() => {
    cleanCurrentInterval();
    const shouldUpdateBlueprint = isDragging;
    if (shouldUpdateBlueprint) {
      intervalRef.current = setInterval(() => {
        if (inEditMode && activeId) {
          if (
            JSON.stringify(dropAreaPositions) ===
            JSON.stringify(dropAreaDebounceRef.current)
          ) {
            updateBlueprint(size);
            cleanCurrentInterval();
          } else {
            dropAreaDebounceRef.current = dropAreaPositions;
          }
        }
      }, 150);
      return cleanCurrentInterval;
    }
  }, [widgetOrder]);

  // exit edit mode when custom page is no longer selected
  useEffect(() => {
    if (!selected && inEditMode) {
      setInEditMode(false);
    }
  }, [selected]);

  // AX keyboard nav group enhancement
  useKeyNavGroups({
    rootRef: boardRef,
    groupSelector: '[data-key-nav-group^="select-widget"]',
    keyNavMode: KEY_NAV_MODE.BOTH,
    isDisabled: inEditMode || isGroupKeyNavDisabled,
  });

  return (
    <div className={c.custom} role="region" aria-label="Widget board">
      <PageContainer pageId={pageId}>
        <Header
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
          updatingWidgetOrderStatus={updatingWidgetOrderStatus}
          setUpdatingWidgetOrderStatus={setUpdatingWidgetOrderStatus}
          pageId={pageId}
          pageData={pageData}
          setNewWidgetId={setNewWidgetId}
          responsiveSize={size}
          setWidgetOrder={setWidgetOrder}
          isPageEmpty={isPageEmpty}
        />
        {isPageEmpty ? <EmptyPage /> : null}

        {!inEditMode && !isPageEmpty ? (
          <div
            className={c.board}
            ref={boardRef}
            aria-label="Use the arrow keys to navigate between widgets and tab key to begin navigating inside a widget."
          >
            {widgetOrder?.map((id, i) => renderWidget(id, widgets[id], i))}
          </div>
        ) : null}

        {inEditMode && !isPageEmpty ? (
          <SortableDragAndDropWrapper
            sortableItems={widgetOrder}
            onDragStart={activateWidget}
            onDragOver={swapWidgetOrder}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
            sortingStrategy={rectSortingStrategy}
            screenReaderAnnouncements={screenReaderAnnouncements}
            staticSortableObjectName="widget"
            dndType={DND_TYPE.CUSTOM_WIDGETS}
          >
            <div className={c.editBoard}>
              {renderRows()}
              {renderColumns()}
            </div>
            <div className={c.board} ref={boardRef}>
              {widgetOrder?.map((id, i) => (
                <SortableGridItem
                  id={id}
                  key={id}
                  gridColumns={getColumnSpan(size, widgets[id].size)}
                  keyNavItem={{
                    'aria-label': `Sort widget: ${getWidgetNameByTypeId(
                      widgets[id].typeId
                    )}.`,
                  }}
                >
                  {id !== activeId && renderWidget(id, widgets[id], i)}
                  {id === activeId && (
                    <div
                      className={c.dropArea}
                      ref={dropAreaRef}
                      style={{
                        height: `${boardRef.current?.children[i]?.children[0].offsetHeight}px`,
                      }}
                    />
                  )}
                </SortableGridItem>
              ))}
            </div>
            <DragOverlay>
              {activeId &&
                renderWidget(
                  activeId,
                  widgets[activeId],
                  widgetOrder.indexOf(activeId),
                  true
                )}
            </DragOverlay>
          </SortableDragAndDropWrapper>
        ) : null}
      </PageContainer>
    </div>
  );
});

export default Custom;
